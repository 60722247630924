import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import IndexLayout from '@layouts/index';
import Header from '@components/Header';
import Footer from '@components/Footer';
import PageHeader from '@components/PageHeader';
import Container from '@components/Container';
import './terms.scss';
import links from '@constants/links';
import emails from '@constants/emails';
import TOCListItem from '@components/TOCListItem';

const Terms: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>('');
  const sidebar = React.createRef<HTMLElement>();
  useEffect(() => {
    const Stickyfill = require('stickyfilljs');

    Stickyfill.add(sidebar);
  }, []);

  const onTOCListItemClick = (slug: string) => {
    setCurrentSection(slug);
    scrollTo(`#${slug}`);
  };

  return (
    <>
      <IndexLayout pageName="terms" helmet={{ title: 'Terms of Use - TileDB', description: 'Learn more about our Terms of Use.' }}>
        <Header />
        <PageHeader title="Terms of Use" date={{ day: 8, month: 3, year: 2021 }} />
        <Container>
          <main className="main">
            <div className="wrapper">
              <article className="legal-article">
                <p>
                  Welcome to TileDB. Please read on to learn the rules and restrictions that govern your use of our website(s), products,
                  services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the
                  Services, please contact us at:
                </p>
                <p>
                  Email:{' '}
                  <a href={`mailto:${emails.support}`} className="hover-link">
                    {emails.support}
                  </a>
                  <br />
                  Address: TileDB, Inc., CIC, 1 Broadway, Cambridge, MA 02142, USA
                </p>
                <p>
                  These Terms of Use (the “Terms”) are a binding contract between you and <strong>TILEDB, INC.</strong> (“TileDB,” “we” and
                  “us”). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in
                  effect while you use the Services. These Terms include the provisions in this document as well as those in the{' '}
                  <Link to={links.privacyPolicy} className="hover-link">
                    Privacy Policy
                  </Link>
                  .
                </p>

                <p>
                  <strong>
                    Additionally, your use of or participation in certain Services may also be subject to additional policies, rules and/or
                    conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using
                    or participating in any such Services, you agree to also comply with these Additional Terms.
                  </strong>
                </p>

                <p>
                  <strong>Please read these Terms carefully.</strong> They cover important information about Services provided to you and
                  any charges, taxes, and fees we bill you.{' '}
                  <strong>
                    These Terms include information about{' '}
                    <a className="hover-link" href="#will-these-terms">
                      future changes to these Terms
                    </a>
                    ,{' '}
                    <a className="hover-link" href="#do-the-services">
                      automatic renewals
                    </a>
                    ,{' '}
                    <a className="hover-link" href="#LoL">
                      limitations of liability
                    </a>
                    ,{' '}
                    <a className="hover-link" href="#arbitration-agreement">
                      a class action waiver and resolution of disputes by arbitration instead of in court
                    </a>
                    . PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL
                    OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
                  </strong>
                </p>

                <p>
                  <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE
                  ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
                  ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('will-these-terms');
                  }}
                >
                  <h2 className="anchor-target" id="will-these-terms">
                    Will these Terms ever change?
                  </h2>
                </InView>

                <p>
                  We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve
                  the right to change the Terms at any time, but if we do, we will place a notice on the TileDB website, send you an email,
                  and/or notify you by some other means.
                </p>

                <p>
                  If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able
                  to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to
                  all of the changes.
                </p>

                <p>
                  Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in
                  writing and signed by both you and us.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-about-my-privacy');
                  }}
                >
                  <h2 id="what-about-my-privacy">What about my privacy?</h2>
                </InView>

                <p>
                  TileDB takes the privacy of its users very seriously. For the current TileDB Privacy Policy, please click{' '}
                  <Link className="hover-link" to={links.privacyPolicy}>
                    here
                  </Link>
                  .
                </p>

                <h3>Children’s Online Privacy Protection Act</h3>

                <p>
                  The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent
                  before they knowingly collect personally identifiable information online from children who are under 13 years of age. We
                  do not knowingly collect or solicit personally identifiable information from children under 13 years of age; if you are a
                  child under 13 years of age, please do not attempt to register for or otherwise use the Services or send us any personal
                  information. If we learn we have collected personal information from a child under 13 years of age, we will delete that
                  information as quickly as possible. If you believe that a child under 13 years of age may have provided us personal
                  information, please contact us at{' '}
                  <a className="hover-link" href={emails.support}>
                    {emails.support}
                  </a>
                  .
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-are-the-basics');
                  }}
                >
                  <h2 id="what-are-the-basics">What are the basics of using TileDB?</h2>
                </InView>

                <p>
                  You may be required to sign up for an account, select a password and user name (“TileDB User ID”), and provide us with
                  certain information or data, such as your contact information.{' '}
                </p>

                <p>
                  If you plan to use the Services for your personal purposes, and not on behalf of or in connection with any of your work or
                  responsibilities for any of your employers, institutions, universities, or labs (each, an “Institution”), please use your
                  personal email address not associated with any of such Institutions to sign up for TileDB’s Services (“Personal Account”).
                </p>

                <p>
                  If you plan to use the Services for, on behalf of, or in connection with any of your work or responsibilities for any
                  Institution, please use your email address associated with such Institution to sign up for TileDB’s Services
                  (“Institutional Account”).
                </p>

                <p>
                  Before signing up, please contact your Institution to verify whether it has purchased any of the Services. You may be
                  eligible or required to use the Services as an authorized user within such Institution pursuant to a separate written
                  agreement entered into by such Institution with TileDB (each, an “Enterprise Agreement”), and such Enterprise Agreement
                  will supersede these Terms and control your use of the Services through the Institutional Account. If your Institution has
                  not signed up for the Services yet, you may also use your email address associated with such Institution to sign up for
                  TileDB’s Services. You acknowledge and agree that your use of an email address associated with an Institution for TileDB’s
                  Services constitutes your acceptance that such use shall only be for, on behalf of, or in connection with your work or
                  responsibilities for such Institution, and not for your personal purposes. Before such Institution signs up for the
                  Services, your use of the Services via your email address associated with such Institution will be governed by these
                  Terms. When such Institution enters into an Enterprise Agreement for the Services, you acknowledge and agree that your
                  Institution may request TileDB to transfer any of your accounts using an email address associated with such Institution to
                  such Institution, in which event your use of the Services will be governed by the applicable Enterprise Agreement.
                </p>

                <p>
                  You further acknowledge and agree that in connection with the transfer of your TileDB account to your Institution, your
                  User Submissions (defined below) stored in such account may be transferred over to your Institution, which may have
                  certain rights over your User Submissions, including without limitation, the right to access, edit, export, or delete any
                  part of such User Submissions, and the right to grant access to such User Submissions to other third parties. Note that
                  your Institution, not TileDB, decides and controls what rights your Institution may have over your User Submission, and
                  you may be subject to any agreement you have entered into with your Institution when using the Services through an email
                  address associated with such Institution.
                </p>

                <p>
                  It is important that you separate your personal projects from your work or responsibilities for any Institution by signing
                  up a Personal Account for your personal projects using your personal email address, and using an Institutional Account
                  only for, on behalf of, or in connection with your work or responsibilities for such Institution. You’re solely
                  responsible for your choice of Personal Account or Institutional Account and hereby release and hold TileDB harmless from
                  any and all liability arising from such choice, including, without limitation, any transfer of or grant of rights to your
                  User Submissions to any Institution as described herein.
                </p>

                <p>
                  When using either Personal Account or Institutional Account, you represent and warrant that (i) you (and your licensors)
                  have all right, title and interest (including, without limitation, sole ownership of) all of your User Submissions and the
                  intellectual property rights with respect thereto, (ii) you have all the necessary rights, consents, and approvals to
                  grant TileDB the right and license to provide the Services to you or to use your User Submissions as otherwise permitted
                  under these Terms. You, and not TileDB, have the sole responsibility for the accuracy, quality, integrity, legality,
                  reliability, appropriateness, and intellectual property ownership or right to use of all your User Submissions. You agree
                  that you will not (and will not permit others to) violate any agreement with or rights of any third party (including your
                  current and former Institutions) or, use or disclose at any time any third party’s confidential information or
                  intellectual property in connection with your use of the Services. If TileDB receives any notice or claim that any of your
                  User Submissions, or activities hereunder with respect to any User Submission, may infringe or violate rights of a third
                  party (a “Claim”), TileDB may (but is not required to) suspend activity hereunder with respect to such User Submission and
                  you shall indemnify TileDB from all liability, damages, settlements, attorney fees and other costs and expenses in
                  connection with any such Claim, as incurred.
                </p>

                <p>
                  You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select
                  as your TileDB User ID a name that you do not have the right to use, or another person’s name with the intent to
                  impersonate that person. You may not transfer your account to anyone else without our prior written permission.
                </p>

                <p>
                  You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received
                  your parent’s or guardian’s permission to use the Services and have gotten your parent or guardian to agree to these Terms
                  on your behalf). If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that
                  you are authorized to agree to these Terms on that organization’s or entity’s behalf and bind them to these Terms (in
                  which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or
                  entity).
                </p>

                <p>
                  You will only use the Services in a manner that complies with all laws that apply to you. If your use of the Services is
                  prohibited by applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible for your
                  using the Services in a way that breaks the law.
                </p>

                <p>
                  You will not share your TileDB User ID, account or password with anyone, and you must protect the security of your TileDB
                  User ID, account, password and any other access tools or credentials. You’re responsible for any activity associated with
                  your TileDB User ID and account.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-about-messaging');
                  }}
                >
                  <h2 id="what-about-messaging">What about messaging?</h2>
                </InView>

                <p>
                  As part of the Services, you may receive communications through the Services, including messages that TileDB sends you
                  (for example, via email or SMS). When signing up for the Services, you will receive a welcome message and instructions on
                  how to stop receiving messages.{' '}
                  <strong>
                    By signing up for the Services and providing us with your wireless number, you confirm that you want TileDB to send you
                    information regarding your account or transactions with us, which may include TileDB using automated dialing technology
                    to text you at the wireless number you provided, and you agree to receive communications from TileDB, and you represent
                    and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented
                    to receive communications from TileDB.
                  </strong>{' '}
                  You agree to indemnify and hold TileDB harmless from and against any and all claims, liabilities, damages (actual and
                  consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the
                  foregoing.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('are-there-restrictions');
                  }}
                >
                  <h2 id="are-there-restrictions">Are there restrictions in how I can use the Services?</h2>
                </InView>

                <p>
                  You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as that term is
                  defined below), to the Services, or otherwise use or interact with the Services, in a manner that:
                </p>

                <ol className="grammar-list__wrapper">
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(a)</span>
                    <span className="grammar-list__text">
                      infringes or violates the intellectual property rights or any other rights of anyone else (including TileDB);
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(b)</span>
                    <span className="grammar-list__text">
                      violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any
                      other purpose not reasonably intended by TileDB;
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(c)</span>
                    <span className="grammar-list__text">
                      is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(d)</span>
                    <span className="grammar-list__text">
                      jeopardizes the security of your TileDB User ID, account or anyone else’s (such as allowing someone else to log in to
                      the Services as you);
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(e)</span>
                    <span className="grammar-list__text">
                      attempts, in any manner, to obtain the password, account, or other security information from any other user;
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(f)</span>
                    <span className="grammar-list__text">
                      violates the security of any computer network, or cracks any passwords or security encryption codes;
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(g)</span>
                    <span className="grammar-list__text">
                      runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are
                      activated while you are not logged into the Services, or that otherwise interfere with the proper working of the
                      Services (including by placing an unreasonable load on the Services’ infrastructure);
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(h)</span>
                    <span className="grammar-list__text">
                      “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of
                      manual or automated means);
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(i)</span>
                    <span className="grammar-list__text">copies or stores any significant portion of the Content; or</span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">(j)</span>
                    <span className="grammar-list__text">
                      decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of
                      or relating to the Services.
                    </span>
                  </li>
                </ol>

                <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services. </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('do-i-have-to-grant');
                  }}
                >
                  <h2 id="do-i-have-to-grant">Do I have to grant any licenses to TileDB or to other users? </h2>
                </InView>

                <p>
                  Anything you post, input, upload, share, store, or otherwise provide through the Services is your "User Submission." Some
                  User Submissions are viewable by other users. In order to display your User Submissions on the Services, and to allow
                  other users to enjoy them (where applicable), you grant us certain rights in those User Submissions. Please note that all
                  of the following licenses are subject to our{' '}
                  <Link className="hover-link" to={links.privacyPolicy}>
                    Privacy Policy
                  </Link>{' '}
                  to the extent they relate to User Submissions that are also your personally-identifiable information.
                </p>

                <p>
                  For all User Submissions, you hereby grant TileDB a license to translate, modify (for technical purposes, for example
                  making sure your content is viewable on an iPhone as well as a computer) and reproduce and otherwise act with respect to
                  such User Submissions, in each case to enable us to operate the Services, as described in more detail below. This is a
                  license only — your ownership in User Submissions is not affected.
                </p>

                <p>
                  If you store a User Submission in your own Personal Account, in a manner that is not viewable by any other user except you
                  (a "Personal User Submission"), you grant TileDB the license above, as well as a license to display, perform, and
                  distribute your Personal User Submission for the sole purpose of making that Personal User Submission accessible to you
                  and providing the Services necessary to do so.
                </p>

                <p>
                  If you share a User Submission only in a manner that only certain specified users can view (for example, a project shared
                  with one or more other users) (a "Limited Audience User Submission"), then you grant TileDB the licenses above, as well as
                  a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that
                  Limited Audience User Submission accessible to such other specified users, and providing the Services necessary to do so.
                  Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and
                  exercise all rights in it, as permitted by the functionality of the Services. You acknowledge and agree that your User
                  Submissions stored in your Institutional Account shall be deemed as Limited Audience User Submission, and unless otherwise
                  expressly requested by you in writing, any and all of the User Submission stored in your Institutional Account may be
                  accessed, viewed, shared, exported, or otherwise exploited by any other authorized users of your Institution. Your
                  Institution, and not TileDB, has the right to decide what rights and authorities you and/or other authorized users may
                  have with respect to such User Submission stored in your Institutional Account, and your agreement with such Institution
                  may impose additional restrictions.
                </p>

                <p>
                  If you share a User Submission publicly on the Services and/or in a manner that more than just you or certain specified
                  users can view, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements,
                  enhancements, and/or feature requests relating to the Services (each of the foregoing, a "Public User Submission"), then
                  you grant TileDB the licenses above, as well as a license to display, perform, and distribute your Public User Submission
                  for the purpose of making that Public User Submission accessible to all TileDB users and providing the Services necessary
                  to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in
                  connection with the Services and/or otherwise in connection with TileDB business, provided that TileDB will try to notify
                  you if it uses your Public User Submission for any reason other than displaying it on the Services. Also, you grant all
                  other users of the Services a license to access that Public User Submission, and to use and exercise all rights in it, as
                  permitted by the functionality of the Services.
                </p>

                <p>
                  You agree that the licenses you grant are royalty-free, perpetual, sublicenseable, irrevocable, and worldwide, provided
                  that when you delete your TileDB account, we will stop displaying your User Submissions (other than Public User
                  Submissions, which may remain fully available) to other users (if applicable), but you understand and agree that it may
                  not be possible to completely delete that content from TileDB's records, and that your User Submissions may remain
                  viewable elsewhere to the extent that they were copied or stored by other users.
                </p>

                <p>
                  Finally, you understand and agree that TileDB, in performing the required technical steps to provide the Services to our
                  users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to
                  the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the
                  rights to do so.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-are-my-rights');
                  }}
                >
                  <h2 id="what-are-my-rights">What are my rights in the Services?</h2>
                </InView>

                <p>
                  The materials displayed or performed or available on or through the Services, including, but not limited to, text,
                  graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the “Content”) are protected
                  by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules,
                  information, and restrictions contained in any Content you access through the Services, and you won’t use, copy,
                  reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell,
                  commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the
                  owner of that Content or (ii) in a way that violates someone else’s (including TileDB) rights.
                </p>

                <p>
                  Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and
                  non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services.
                  Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is
                  expressly prohibited without prior written permission from us. You understand that TileDB owns the Services. You won’t
                  modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this
                  Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy
                  or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this
                  section still apply.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('who-is-responsible');
                  }}
                >
                  <h2 id="who-is-responsible">Who is responsible for what I see and do on the Services?</h2>
                </InView>

                <p>
                  Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the
                  person from whom such Content originated, and you access all such information and Content at your own risk, and we aren’t
                  liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in
                  connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the
                  Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all
                  liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any
                  users with whom you interact in using the Services and are not responsible for which users gain access to the Services.
                </p>

                <p>
                  You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have
                  all rights necessary to do so, in the manner in which you contribute it.
                </p>

                <p>
                  The Services may contain links or connections to third-party websites or services that are not owned or controlled by
                  TileDB. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and
                  that TileDB is not responsible for such risks.{' '}
                </p>

                <p>
                  TileDB has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or
                  opinions expressed in any third-party websites or by any third party that you interact with through the Services. In
                  addition, TileDB will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We
                  encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each
                  third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any
                  and all liability arising from your use of any third-party website or service.{' '}
                </p>

                <p>
                  Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery
                  of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are
                  solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or
                  appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that
                  TileDB shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.
                </p>

                <p>
                  If there is a dispute between participants on this site or Services, or between users and any third party, you agree that
                  TileDB is under no obligation to become involved. In the event that you have a dispute with one or more other users, you
                  release TileDB, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind
                  or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to
                  such disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of
                  any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing
                  party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him
                  or her, would have materially affected his or her settlement with the debtor or released party.”
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('will-tiledb-change-services');
                  }}
                >
                  <h2 id="will-tiledb-change-services">Will TileDB ever change the Services?</h2>
                </InView>

                <p>
                  We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the
                  Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the
                  Services. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not
                  limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and
                  without notice.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('do-services-cost');
                  }}
                >
                  <h2 className="anchor-target" id="do-services-cost">
                    Do the Services cost anything?
                  </h2>
                </InView>

                <p>
                  The Services may be free or we may charge a fee for using the Services. If you are using a free version of the Services,
                  we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such
                  Services, you must pay all applicable fees for such Services. Note that if you elect to receive text messages through the
                  Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility. You
                  should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the
                  Services.
                </p>

                <ol className="grammar-list__wrapper">
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">a.</span>
                    <span className="grammar-list__text">
                      <strong>Paid Services.</strong> Certain of our Services may be subject to payments now or in the future (the “Paid
                      Services”). Please see our Paid Services page:{' '}
                      <Link className="hover-link" to={links.cloud}>
                        https://tiledb.com/cloud
                      </Link>{' '}
                      for a description of the current Paid Services. Please note that any payment terms presented to you in the process of
                      using or signing up for a Paid Service are deemed part of these Terms.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">b.</span>
                    <span className="grammar-list__text">
                      <strong>Billing.</strong> We use a third-party payment processor (the “Payment Processor”) to bill you through a
                      payment account linked to your account on the Services (your “Billing Account”) for use of the Paid Services. The
                      processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in
                      addition to these Terms.Currently, we use Stripe, Inc. as our Payment Processor. You can access Stripe’s Terms of
                      Service at
                      <a href="https://stripe.com/us/checkout/legal" target="_blank" rel="nofollow noreferrer" className="hover-link">
                        https://stripe.com/us/checkout/legal
                      </a>{' '}
                      and their Privacy Policy at{' '}
                      <a href="https://stripe.com/us/privacy" target="_blank" rel="nofollow noreferrer" className="hover-link">
                        https://stripe.com/us/privacy
                      </a>
                      . We are not responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to use
                      Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any
                      use of such Paid Services in accordance with the applicable payment terms, and you authorize us, through the Payment
                      Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that
                      selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor makes even
                      if it has already requested or received payment.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">c.</span>
                    <span className="grammar-list__text">
                      <strong>Payment Method.</strong> The terms of your payment will be based on your Payment Method and may be determined
                      by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment
                      Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on
                      your Billing Account upon demand.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">d.</span>
                    <span className="grammar-list__text">
                      <strong>Recurring Billing.</strong> Some of the Paid Services may consist of an initial period, for which there is a
                      one-time charge, followed by recurring period charges as agreed to by you. By choosing a recurring payment plan, you
                      acknowledge that such Services have an initial and recurring payment feature and you accept responsibility for all
                      recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION
                      FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS
                      AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE
                      REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO{' '}
                      <a className="hover-link" href={links.console.user.billing}>
                        ACCOUNT SETTINGS
                      </a>
                      .
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">e.</span>
                    <span className="grammar-list__text">
                      <strong>Current Information Required.</strong> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR
                      BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE
                      (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY
                      NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE
                      OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES
                      TO SUCH INFORMATION CAN BE MADE AT{' '}
                      <a className="hover-link" href={links.console.user.settings}>
                        ACCOUNT SETTINGS
                      </a>
                      . IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF
                      PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">f.</span>
                    <span className="grammar-list__text">
                      <strong>Change in Amount Authorized.</strong> If the amount to be charged to your Billing Account varies from the
                      amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the
                      right to receive, and we shall provide, notice of the amount to be charged and the date of the charge before the
                      scheduled date of the transaction. Any agreement you have with your payment provider will govern your use of your
                      Payment Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges
                      during or at the end of each billing cycle.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">g.</span>
                    <span className="grammar-list__text">
                      <strong>Auto-Renewal for Paid Services.</strong> Unless you opt out of auto-renewal, which can be done through your{' '}
                      <a className="hover-link" href={links.console.user.settings}>
                        account settings
                      </a>
                      , any Paid Services you have signed up for will be automatically extended for successive renewal periods of the same
                      duration as the subscription term originally selected, at the then-current non-promotional rate. To change or resign
                      your Paid Services at any time, go to{' '}
                      <a className="hover-link" href={links.console.user.settings}>
                        account settings
                      </a>
                      . If you terminate a Paid Service, you may use your subscription until the end of your then-current term, and your
                      subscription will not be renewed after your then-current term expires. However, you will not be eligible for a
                      prorated refund of any portion of the subscription fee paid for the then-current subscription period. If you do not
                      want to continue to be charged on a recurring monthly basis, you must cancel the applicable Paid Service through your{' '}
                      <a className="hover-link" href={links.console.user.settings}>
                        account settings
                      </a>{' '}
                      or terminate your TileDB account before the end of the recurring TERM. Paid Services cannot be terminated before the
                      end of the period for which you have already paid, and except as expressly provided in these terms, TileDB will not
                      refund any fees that you have already paid.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">h.</span>
                    <span className="grammar-list__text">
                      <strong>Reaffirmation of Authorization.</strong> Your non-termination or continued use of a Paid Service reaffirms
                      that we are authorized to charge your Payment Method for that Paid Service. We may submit those charges for payment
                      and you will be responsible for such charges. This does not waive our right to seek payment directly from you. Your
                      charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use
                      the Paid Service.
                    </span>
                  </li>
                  <li className="grammar-list__li">
                    <span className="grammar-list__style">i.</span>
                    <span className="grammar-list__text">
                      <strong>Free Trials and Other Promotions.</strong> Any free trial or other promotion that provides access to a Paid
                      Service must be used within the specified time of the trial. You must stop using a Paid Service before the end of the
                      trial period in order to avoid being charged for that Paid Service. If you cancel prior to the end of the trial period
                      and are inadvertently charged for a Paid Service, please contact us at{' '}
                      <a href={`mailto:${emails.support}`} className="hover-link">
                        {emails.support}
                      </a>
                      .
                    </span>
                  </li>
                </ol>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-if-i-want-to-stop');
                  }}
                >
                  <h2 id="what-if-i-want-to-stop">What if I want to stop using the Services?</h2>
                </InView>

                <p>
                  You’re free to do that at any time by contacting us at support@tiledb.com; please refer to our{' '}
                  <Link to={links.privacyPolicy} className="link-hover">
                    Privacy Policy
                  </Link>
                  , as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our
                  Services.
                </p>

                <p>
                  TileDB is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our
                  discretion, including your breach of these Terms. TileDB has the sole right to decide whether you are in violation of any
                  of the restrictions set forth in these Terms.
                </p>

                <p>
                  Account termination may result in destruction of any Content associated with your account, so keep that in mind before you
                  decide to terminate your account.
                </p>

                <p>
                  If you have deleted your account by mistake, contact us immediately at{' '}
                  <a href={`mailto:${emails.support}`} className="hover-link">
                    {emails.support}
                  </a>{' '}
                  – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.
                </p>

                <p>
                  Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example,
                  all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our
                  liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us,
                  including without limitation the arbitration agreement.
                </p>

                <InView
                  as="section"
                  onChange={(inView) => {
                    if (inView) setCurrentSection('what-else-do-i-need-to-know');
                  }}
                >
                  <h2 id="what-else-do-i-need-to-know">What else do I need to know?</h2>
                </InView>

                <p>
                  <strong>Warranty Disclaimer.</strong> TileDB and its licensors, suppliers, partners, parent, subsidiaries or affiliated
                  entities, and each of their respective officers, directors, members, employees, consultants, contract employees,
                  representatives and agents, and each of their respective successors and assigns (TileDB and all such parties together, the
                  “TileDB Parties”) make no representations or warranties concerning the Services, including without limitation regarding
                  any Content contained in or accessed through the Services, and the TileDB Parties will not be responsible or liable for
                  the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any
                  claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to
                  your participation in, the Services. The TileDB Parties make no representations or warranties regarding suggestions or
                  recommendations of services or products offered or purchased through or in connection with the Services. THE SERVICES AND
                  CONTENT ARE PROVIDED BY TILEDB (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND,
                  EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
                  LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>

                <p className="anchor-target" id="LoL">
                  <strong>Limitation of Liability.</strong> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND
                  UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE
                  TILEDB PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
                  CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF
                  GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR
                  TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE
                  AMOUNTS PAID AND/OR PAYABLE BY YOU TO TILEDB IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING
                  THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
                  LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY
                  TO YOU.{' '}
                </p>

                <p>
                  <strong>Indemnity.</strong> You agree to indemnify and hold the TileDB Parties harmless from and against any and all
                  claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or
                  in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party
                  using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we
                  will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to
                  deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).
                </p>

                <p>
                  <strong>Assignment.</strong> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder,
                  or your Services account, in any way (by operation of law or otherwise) without TileDB prior written consent. We may
                  transfer, assign, or delegate these Terms and our rights and obligations without consent.
                </p>

                <p>
                  <strong>Choice of Law.</strong> These Terms are governed by and will be construed under the Federal Arbitration Act,
                  applicable federal law, and the laws of the Commonwealth of Massachusetts, without regard to the conflicts of laws
                  provisions thereof.
                </p>

                <p className="anchor-target" id="arbitration-agreement">
                  <strong>Arbitration Agreement.</strong> Please read the following ARBITRATION AGREEMENT carefully because it requires you
                  to arbitrate certain disputes and claims with TileDB and limits the manner in which you can seek relief from TileDB. Both
                  you and TileDB acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter
                  of these Terms, TileDB officers, directors, employees and independent contractors (“Personnel”) are third-party
                  beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be
                  deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(a) Arbitration Rules; Applicability of Arbitration Agreement.</em>
                  </strong>{' '}
                  The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or
                  relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a precondition to
                  either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by
                  binding arbitration in Suffolk County, Massachusetts. The arbitration will proceed in the English language, in accordance
                  with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with
                  substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be
                  selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by
                  such arbitrator may be entered in any court of competent jurisdiction.{' '}
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(b) Costs of Arbitration.</em>
                  </strong>{' '}
                  The Rules will govern payment of all arbitration fees. TileDB will pay all arbitration fees for claims less than
                  seventy-five thousand ($75,000) dollars. TileDB will not seek its attorneys’ fees and costs in arbitration unless the
                  arbitrator determines that your claim is frivolous.{' '}
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(c) Small Claims Court; Infringement.</em>
                  </strong>{' '}
                  Either you or TileDB may assert claims, if they qualify, in small claims court in Suffolk County, Massachusetts or any
                  United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes,
                  each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent
                  jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights,
                  trademarks, trade secrets, patents or other intellectual property rights.{' '}
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(d) Waiver of Jury Trial.</em>
                  </strong>{' '}
                  YOU AND TILEDB WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY.
                  You and TileDB are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are
                  typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited
                  review by a court. In any litigation between you and TileDB over whether to vacate or enforce an arbitration award, YOU
                  AND TILEDB WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(e) Waiver of Class or Consolidated Actions.</em>
                  </strong>{' '}
                  ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL
                  BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                  CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed
                  invalid or unenforceable, neither you nor TileDB is entitled to arbitration; instead all claims and disputes will be
                  resolved in a court as set forth in (g) below.{' '}
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(f) Opt-out.</em>
                  </strong>{' '}
                  You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to
                  the following address: TileDB, Inc. CIC, 1 Broadway, Cambridge, MA 02142, USA postmarked within thirty (30) days of first
                  accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone
                  number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration
                  agreement.
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(g) Exclusive Venue.</em>
                  </strong>{' '}
                  If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits
                  either you or TileDB to litigate any dispute arising out of or relating to the subject matter of these Terms in court,
                  then the foregoing arbitration agreement will not apply to either party, and both you and TileDB agree that any judicial
                  proceeding (other than small claims actions) will be brought in the state or federal courts located in, respectively,
                  Suffolk County, Massachusetts, or the federal district in which that county falls.{' '}
                </p>

                <p className="indent-1">
                  <strong>
                    <em>(h) Severability.</em>
                  </strong>{' '}
                  If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to
                  be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This
                  arbitration agreement will survive the termination of your relationship with TileDB.
                </p>

                <p>
                  <strong>Miscellaneous.</strong> You will be responsible for paying, withholding, filing, and reporting all taxes, duties,
                  and other governmental assessments associated with your activity in connection with the Services, provided that the TileDB
                  may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either
                  you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any
                  provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the
                  minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and
                  TileDB agree that these Terms are the complete and exclusive statement of the mutual understanding between you and TileDB,
                  and that these Terms supersede and cancel all previous written and oral agreements, communications and other
                  understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an
                  employee, agent, partner, or joint venture of TileDB, and you do not have any authority of any kind to bind TileDB in any
                  respect whatsoever.{' '}
                </p>

                <p>
                  Except as expressly set forth in the section above regarding the arbitration agreement, you and TileDB agree there are no
                  third-party beneficiaries intended under these Terms.
                </p>
              </article>
              <aside className="contents" ref={sidebar}>
                <h3>Contents:</h3>
                <ul className="toc">
                  <TOCListItem
                    currentSection={currentSection}
                    slug="will-these-terms"
                    label="Will these Terms ever change?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-about-my-privacy"
                    label="What about my privacy?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-are-the-basics"
                    label="What are the basics of using TileDB?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-about-messaging"
                    label="What about messaging?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="are-there-restrictions"
                    label="Are there restrictions in how I can use the Services?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="do-i-have-to-grant"
                    label="Do I have to grant any licenses to TileDB or to other users?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-are-my-rights"
                    label="What are my rights in the Services?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="who-is-responsible"
                    label="Who is responsible for what I see and do on the Services?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="will-tiledb-change-services"
                    label="Will TileDB ever change the Services?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="do-services-cost"
                    label="Do the Services cost anything?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-if-i-want-to-stop"
                    label="What if I want to stop using the Services?"
                    onClick={onTOCListItemClick}
                  />
                  <TOCListItem
                    currentSection={currentSection}
                    slug="what-else-do-i-need-to-know"
                    label="What else do I need to know?"
                    onClick={onTOCListItemClick}
                  />
                </ul>
              </aside>
            </div>
          </main>
        </Container>
        <Footer />
      </IndexLayout>
    </>
  );
};

export default Terms;
